@use "sass:list" as list;

@mixin smooth-transition-all {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin shadow-transition {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin elevation($value){
    @if($value == 0){
        box-shadow: none;
    }
    @if($value == 1){
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
                    0px 1px 1px 0px rgba(0,0,0,0.14),
                    0px 1px 3px 0px rgba(0,0,0,0.12);
    }
    @if($value == 2){
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),
                    0px 2px 2px 0px rgba(0,0,0,0.14),
                    0px 1px 5px 0px rgba(0,0,0,0.12);
    }
    @if($value == 3){
        box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),
                    0px 3px 4px 0px rgba(0,0,0,0.14),
                    0px 1px 8px 0px rgba(0,0,0,0.12);
    }
}

@function primary($key: "primary", $variant: "none"){
    $p: #4b60bf;
    $color-map: (
        "900": #00177d,
        "800": #002993,
        "700": #0f339e,
        "600": #1d3daa,
        "500": #2345b4,
        "400": $p,
        "300": #6c7cca,
        "200": #97a1d9,
        "100": #c0c6e8,
        "50": #e6e8f6,
        "primary": $p
    );
    $contrast-map: (
        "900": #fff,
        "800": #fff,
        "700": #fff,
        "600": #fff,
        "500": #fff,
        "400": #fff,
        "300": #000,
        "200": #000,
        "100": #000,
        "50": #000,
        "primary": #fff
    );
    @if $variant == "contrast" {
        @return map-get($map: $contrast-map, $key: $key);
    }
    @return map-get($map: $color-map, $key: $key)
};

@function alert($key: "primary", $variant: "none"){
    $p: #EF5350;
    $color-map: (
        "900": #B71C1C,
        "800": #C62828,
        "700": #D32F2F,
        "600": #E53935,
        "500": #F44336,
        "400": $p,
        "300": #E57373,
        "200": #EF9A9A,
        "100": #FFCDD2,
        "50": #FFEBEE,
        "primary": $p,
        "contrast": #fff
    );
    $contrast-map: (
        "900": #fff,
        "800": #fff,
        "700": #fff,
        "600": #fff,
        "500": #fff,
        "400": #fff,
        "300": #000,
        "200": #000,
        "100": #000,
        "50": #000,
        "primary": #fff
    );
    @if $variant == "contrast" {
        @return map-get($map: $contrast-map, $key: $key);
    }
    @return map-get($map: $color-map, $key: $key)
};