@use "../../core/styles/common.scss" as common;
@use "../../core/styles/material.scss" as mui;

.one-circular-button {
    border: none;
    border-radius: 50%;
    width: 35vw;
    height: 35vw;
    user-select: none;
    font-size: 2em;
    color: mui.primary($variant: "contrast");
    background-color: mui.primary();

    @include common.centered-flex();
    @include mui.smooth-transition-all;
    @include mui.elevation(1);
    &:active {
        background-color: mui.primary("700");
        @include mui.elevation(3);
    }

    &--fill {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    &--white {
        background-color: white;
    }
}