@use "sass:list";

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
  
    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
  
    @return $string;
}

@mixin sub($attr, $value) {
    &-#{str-replace($value, " ", "-")} {
        #{$attr}: #{$value};
    }
}

@mixin subs($name, $attr, $values) {
    &--#{$name} {
        @each $value in $values {
            @include sub($attr, $value);
        }
    }
}

@function joinAll($args...){
    $res: ();
    @each $l in $args {
        $res: list.join($res, $l);
    }
    @return $res;
}

$flex-keywords: ("normal", "strech");
$flex-direction: ("column" , "row" , "column-reverse" , "row-reverse");
$partial-positional: ("center", "start", "end", "flex-start", "flex-end");
$positional: list.join(("left", "right"), $partial-positional);
$distributed: ("space-between", "space-around", "space-evenly");
$baseline: ("baseline" , "first baseline" , "last baseline");
$overflow: ("safe center", "unsafe center");
$global: ("inherit" , "initial" , "revert" , "unset");

$justify-values: joinAll($flex-keywords, $positional, $distributed, $overflow, $global);
$align-values: joinAll($flex-keywords, $partial-positional, $baseline, $overflow, $global);
$direction-values: joinAll($flex-direction, $global);

.one-flex {
    display: flex;

    @include subs("justify", "justify-content", $justify-values);
    @include subs("align", "align-items", $align-values);
    @include subs("direction", "flex-direction", $direction-values);

    &--fullsize {
        width: 100%;
        height: 100%;
    }

    &--wide {
        width: 100%;
    }
}