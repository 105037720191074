@use "../../core/styles/material.scss" as mui;

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.one-home {
    animation: fadeIn 400ms ease-out 0s 1 forwards;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    height: 100%;
    &--fadeOut {
        animation-direction: reverse;
        animation-timing-function: ease-in;
        animation-duration: 1000ms;
    }
}