@use "../../core/styles/material.scss" as mui;

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.one-loader {
    width: 48px;
    height: 48px;
    border: 5px solid mui.primary();
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotate 1s linear infinite;

    &--alternate {
        border: 5px solid mui.primary($variant: "contrast");
        border-bottom-color: transparent;
    }

    &--small {
        border-width: 2px;
        width: 16px;
        height: 16px;
    }
}